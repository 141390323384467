const vuexInit = {
    platMap: {
        'zhongtuo': {
            id: 205,
            name: "京学讲坛",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoLogo1.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_jingxue.ico',
        },
        'jiazhang': { //改成0-6岁家长研修社
            id: 107,
            name: "育儿学堂",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuojiazhangLogo4.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico'
        },
        'guangxi': { //广西卫健委
            id: 0,
            name: "中托讲坛",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoLogo1.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico'
        },
        'zhijiao': { //产教研里的 教
            id: 100,
            name: "托育职业教育学习平台",
            logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoLogo1.png',
            faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_chongdong.ico'
        }
    }
  }
  export default vuexInit